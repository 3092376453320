<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
      
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Legal Details (Documents shall be added only in PDF format)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Contract Letter</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.contractLetter">
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.contractLetter">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Contract Letter
                    </v-btn>
                  </a>
                </v-flex>
              </v-layout>
            </v-card>
            </v-flex>
            <v-flex xs12 sm5 text-left>
              <span style="color: red; font-size:12px">Contract Letter Is Not Uploaded</span>
            </v-flex>
          </v-layout>
          
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Offer Letter </span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.offerLetter">
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.offerLetter">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Offer Letter
                    </v-btn>
                  </a>
                </v-flex>
              </v-layout>
              </v-card>
            </v-flex>
            <v-flex xs12 sm5 text-left>
              <span style="color: red; font-size:12px">Offer Letter Is Not Uploaded</span>
            </v-flex>
          </v-layout>
        
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showSnackBar: false,
        timeout: 5000,
        ServerError: false,
        msg: null,
        contractLetter: "",
        offerLetter: "",
        paasportOrlicence: "",
        formData: new FormData(),
        mydocs:{},
      };
    },
    mounted() {
      this.getMyUploads();
    },
  
    methods: {
      getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/legalDeatils/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
      }
    },
  };
  </script>