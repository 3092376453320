<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#005f32"
      spinner="bar-fade-scale" />
    <v-snackbar v-model="showsnackbar" color="#005f32" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">TARGET DETAILS</span>
          <v-spacer></v-spacer>
          <!-- <v-flex text-end align-self-center>
            <v-btn color="#005f32" rounded outlined class="mb-4" @click="addTargets = true">
              <span style="font-family: kumbhRegular;">Add Targets</span>
            </v-btn>
          </v-flex> -->
          <v-flex text-end align-self-center >
            <v-btn color="#005f32" rounded outlined class="mb-4" @click="addTargets = true">
              <span style="font-family: kumbhRegular;">Add Targets</span>
            </v-btn>

            <!-- Vertical Divider -->
            <v-divider vertical class="mx-2" style="background-color: black; width: 1px; height:37px;">
            </v-divider>

            <v-btn color="#005f32" rounded outlined class="mb-4" @click="uploadTargets = true">
              <span style="font-family: kumbhRegular">Upload Targets</span>
            </v-btn>
          </v-flex>

          <v-flex xs12 align-self-center pt-2>
            <v-layout wrap justify-start>
              <v-flex xs12  text-left>
                <span style="font-family: kumbhRegular; font-size: 14px">*Select the options to filter the
                  results</span>
              </v-flex>
              <v-flex xl3 lg3 md3 sm6 xs12 pr-0 pr-lg-2 pr-md-2 pr-sm-2>
                <v-select clearable v-model="financialYear" :items="financialYears" item-text="name" item-value="name"
                  label="Financial Year" required outlined dense hide-details></v-select>
              </v-flex>
              <v-flex xl3 lg3 md3 sm6 xs12 pt-2 pt-lg-0 pt-md-0 pt-sm-0 pr-0 pr-lg-2 pr-md-2 pr-sm-0>
                <v-select  v-model="status" :items="tragetStatusArray" item-text="name" item-value="name"
                  label="Status" required outlined dense hide-details></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-card-title>
        <v-layout wrap justify-start px-4 >
          <v-layout wrap justify-center>
            <v-flex>
              <v-card outlined elevation="1" class="pa-6">
                <v-layout wrap justify-center>
                  <v-flex xs11 sm11 md11 lg12 xl12 text-left v-for="(target) in targetArray"
                    :key="target.financialYear">
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm6 md3 lg2 xl3 >
                        <span  style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Financial Year
                        </span>
                        <br>
                        <span >{{
                          target.financialYear }}</span>
                    
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 xl3 pt-lg-0 pt-md-0 pt-sm-0 pt-2>
                        <span   style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Met Count
                        </span>
                        <br>
                        <span >{{ target.metCount }}</span>
                 
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 xl3 pt-lg-0 pt-md-0 pt-sm-2 pt-2>
                        <span  style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Not Met Count
                        </span>
                        <br>
                        <span >{{
                          target.notMetCount }}</span>
           
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 xl3 pt-lg-0 pt-md-0 pt-sm-2 pt-2>
                        <span  style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Delayed Met Count
                        </span>
                        <br>
                        <span >{{
                          target.delayedMetCount }}</span>
                   
                      </v-flex>

                      <v-flex xs12 sm6 md3 lg2 xl3 pt-lg-0 pt-md-2 pt-sm-2 pt-2>
                        <span   style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Total Score 
                        </span>
                        <br>
                        <span >{{ target.totalScore }}</span>
                 
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 xl3 pt-lg-0 pt-md-2 pt-sm-2 pt-2>
                        <span  style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Total Weightage
                        </span>
                        <br>
                        <span >{{
                          target.totalWeightage }}</span>
                   
                      </v-flex>
                    

                      <!-- <v-flex xs3>
                        <span style="font-family: kumbhRegular; font-size: 16px; font-weight: bold;">
                          Met Count
                        </span>
                      </v-flex>
                      <v-flex xs3 pt-1>
                        <span style="font-family: kumbhRegular; font-size: 18px;">
                          {{ target.metCount }}
                        </span>
                      </v-flex> -->
                    </v-layout>


                    <!-- <v-layout justify-space-between wrap style="font-family: kumbhRegular; font-size: 18px">
                      <v-flex pt-3>
                        <v-chip color="#4EC57A" text-color="white" class="font-weight-bold">
                          Met Count: {{ target.metCount }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3>
                        <v-chip color="#F17E54" text-color="white" class="font-weight-bold">
                          Not Met Count: {{ target.notMetCount }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3>
                        <v-chip color="#C677F7" text-color="white" class="font-weight-bold">
                          Delayed Met Count: {{ target.delayedMetCount }}
                        </v-chip>
                      </v-flex>
                      <v-flex pt-3>
                        <v-chip color="#F17E54" text-color="white" class="font-weight-bold">
                          Total Weightage: {{ target.totalWeightage }}
                        </v-chip>
                      </v-flex>
                    </v-layout> -->
                  </v-flex>
                </v-layout>
              </v-card>

            </v-flex>
          </v-layout>
          <v-flex xs12 pt-3>
            <v-simple-table class="align-center">
              <template v-slot:default>
                <thead style="background-color: #005f32">
                  <tr>
                    <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                      SL.NO
                    </th>
                    <th style="
                    font-size: 15px;
                    color: #ffffff;
                    font-family: kumbhRegular;
                   
                  "
                  :style="{
                    'width':
                      $vuetify.breakpoint.name == 'xs' ? '0px' :
                        $vuetify.breakpoint.name == 'sm' ? '200px' :
                          $vuetify.breakpoint.name == 'md' ? '300px' : '500px'
                  }"

                  class="text-left">
                      Target

                    </th>
                    <th style="
                 font-size: 15px;
                 color: #ffffff;
                 font-family: kumbhRegular;
               " class="text-left">
                      Target Date

                    </th>

                    <th style="
                        font-size: 15px;
                        color: #ffffff;
                        font-family: kumbhRegular;
                      " class="text-left">
                      Weightage

                    </th>




                    <th style="
              font-size: 15px;
              color: #ffffff;
              font-family: kumbhRegular;
            " class="text-left">
                      Target Status
                    </th>
                    <!-- <th style="
              font-size: 15px;
              color: #ffffff;
              font-family: kumbhRegular;
            " class="text-right">
            View
        </th> -->
                    <!-- <th style="
                    font-size: 15px;
                    color: #ffffff;
                    font-family: kumbhRegular;
                  " class="text-left">
                      Target Verification
                    </th>

                    <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="text-left">
                      Target Status
                    </th>

                    <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        " class="">
                      Edit
                    </th>

                    <th style="
                          font-size: 15px;
                          color: #ffffff;
                          font-family: kumbhRegular;
                        ">
                      Delete
                    </th> -->
                  </tr>
                </thead>
                
                <tbody class="align-center" >
                  <template v-for="(target) in targetArray">
                    <tr class=" text-left align-center" style="cursor:pointer;" v-for="(item,i) in target.targets"
                      :key="item._id" @click="redirectToViewPage(item)">
                      <td>
                        <span  class="" style="font-family: kumbhMedium">{{ i + 1 }}</span>
                      </td>
                      <td style="text-align:justify">
                        <span v-if="item.Targets" class="" style="font-family: kumbhMedium; cursor: pointer">
                         {{ item.Targets }}
                        </span>
                      </td>

                      <td>
                        <span v-if="item.TargetDate" class="" style="font-family: kumbhMedium">{{
                          formatDate(item.TargetDate) }}</span>
                      </td>

                      <td>
                        <span v-if="item.Targets" class="" style="font-family: kumbhMedium">{{ item.weightage }}</span>
                      </td>

                      <td>
                        <v-layout wrap>
                          <v-flex xs12 align-self-center text-left>
                            <span v-if="item.isVerified"
                              style="font-family: kumbhMedium;color:#005f32;text-transform:uppercase;">
                              Verfied
                            </span>
                            <span v-if="!item.isVerified"
                            style="font-family: kumbhMedium;color:#005f32;text-transform:uppercase;">
                            Not Verified
                          </span>

                          </v-flex>
                        </v-layout>
                      </td>


                      <!-- <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left>

                          <v-btn v-if="!item.isVerified" small dark outlined color="#005f32"
                            @click="openVerifyTargetDialog(item)" title="Add">
                            <v-icon small left> mdi-shield-check</v-icon>
                            Verify Target
                          </v-btn>
                          <span v-if="item.isVerified"
                            style="font-family: kumbhMedium;color:#005f32;text-transform:uppercase;">
                            Verified
                          </span>

                        </v-flex>
                      </v-layout>
                    </td> -->
                      <!-- <td   class="text-right">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-right pa-2>
                          <router-link
                            :to="`/Targets/viewTarget?targetId=${item._id}&financialYear=${financialYear}&TargetSheetId=${TargetSheetId}`">
                            <v-icon title="View" color="#005f32">mdi-eye</v-icon>
                          </router-link>

                        </v-flex>
                      </v-layout>
                    </td> -->
                      <!-- <td  class="text-right" v-if="item.isVerified=='true'">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-right pr-10 style="font-family: kumbhMedium;color:#005f32;text-transform:uppercase;">

                          Verified


                        </v-flex>
                      </v-layout>
                    </td> -->



                      <!-- <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-1>
                          <v-btn v-if="!item.tragetStatus && item.isVerified" small dark outlined color="orange"
                            @click="openStatusTargetDialog(item)" title="Add">
                            <v-icon small left>mdi-alert-circle</v-icon>
                            Change Status
                          </v-btn>
                          <span style="font-family: kumbhMedium;color:#005f32;text-transform:uppercase;">
                            {{ item.tragetStatus }}
                          </span>
                        </v-flex>
                      </v-layout>
                    </td> -->
                      <!-- <td v-if="item.tragetStatus">
                      <v-layout wrap>
                        <v-flex xs12 align-self-center text-left pa-1 style="font-family: kumbhMedium;color:#005f32;">
                          {{ item.tragetStatus }}
                        </v-flex>
                      </v-layout>
                    </td> -->

                      <!-- <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center>
                          <v-btn v-if="!item.isVerified" small dark outlined color="primary" @click="editItem(item)"
                            title="Add">
                            <v-icon small left>mdi-pencil</v-icon>
                            Edit
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td> -->
                      <!-- <td>
                      <v-layout wrap>
                        <v-flex xs12 align-self-center>
                          <v-btn v-if="!item.isVerified" small dark outlined color="error"
                            @click="openDeleteDialog(item)" title="Add">
                            <v-icon small left>mdi-delete</v-icon>
                            Delete
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </td> -->
                    </tr>
                  </template>
                </tbody>
              
              <!-- <template v-else>
                <v-layout wrap>
                  <v-flex xs12 align-self-center pa-16>
                    <span class="kumbhRegular">No Data found!</span>
                  </v-flex>
                </v-layout>
              </template> -->
              </template>
            </v-simple-table>
          </v-flex>
        </v-layout>
        
        <!-- </v-card> -->
      </v-flex>
    </v-layout>

    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex align-self-center>
        <div class="pt-2">
          <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
            v-model="currentPage" color="#005f32"></v-pagination>
        </div>
      </v-flex>
    </v-layout>





    <!-- add targets manually -->
    <v-dialog persistent v-model="addTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Add Target Details</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="addTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-select v-model="financialYear1" :items="financialYears1" label="Financial Year" required outlined dense
                  hide-details></v-select>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="targetDate1" label="Target Date" readonly outlined hide-details dense
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#005f32" v-model="targetDate1" :allowed-dates="allowedDates"
                    :picker-date.sync="pickerDateString" scrollable></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field v-model="targetWeightage" label="Target Weightage" required hide-details outlined
                  type="number" dense></v-text-field>

              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="target" label="Target" required hide-details outlined dense></v-textarea>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="addTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Add Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- edit targets manually -->
    <v-dialog persistent v-model="editdialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Edit Target Details</span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="editdialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field readonly v-model="financialYear" label="Financial Year" required hide-details outlined
                  dense></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-menu v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="editingitem.TargetDate" label="Target Date" readonly outlined hide-details
                      dense v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker color="#005f32" v-model="targetDate" :allowed-dates="allowedDates" scrollable
                    @input="updateTargetDate"></v-date-picker>
                </v-menu>

              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-text-field v-model="editingitem.weightage" label="Target Weightage" required hide-details outlined
                  type="number" dense></v-text-field>

              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="editingitem.Targets" label="Target" required hide-details outlined
                  dense></v-textarea>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="edit()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Edit Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Details
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="showDialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-card-text class="pa-6" style="text-align:justify">
            {{ dialogContent }}
          </v-card-text>
        </v-layout>
      </v-card>
    </v-dialog>

    <!-- verify  targets -->
    <v-dialog persistent v-model="verifyTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Verification
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="verifyTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to verify this
                  target?</span>
              </v-flex>

              <v-flex xs12 align-self-center text-right pt-3>
                <v-btn @click="verifyTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Verify Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- status  targets -->
    <!-- <v-dialog persistent v-model="statusTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Status Updation
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="statusTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-select v-model="tragetStatus" :items="filteredTragetStatusArray" label="Target Status" required
                  item-text="name" item-value="name" outlined dense hide-details>
                </v-select>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="additionalDetails" label="Additional Details" required hide-details outlined
                  dense></v-textarea>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-textarea v-model="proofDescription" label="Description" required hide-details outlined
                  dense></v-textarea>
              </v-flex>

              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-card outlined class="pa-2">
                  <v-flex xs12 sm12 align-self-center text-left pa-2 v-if="uploadedFileNames.length > 0"
                    style="font-family:kumbhMedium">
                    <strong>Uploaded Files:</strong>
                    <div v-for="(file, index) in uploadedFileNames" :key="index">
                      {{ file }}
                    </div>
                  </v-flex>
                  <v-flex xs12 sm12 align-self-center text-end pa-2>
                    <v-btn color="#005f32" dark small @click="$refs.proofFile.click()">
                      Upload Proofs
                      <v-icon size="18px">mdi-upload</v-icon>
                    </v-btn>
                    <input v-show="false" ref="proofFile" type="file" accept=".pdf" multiple
                      @change="handleFileUpload" />
                  </v-flex>
                </v-card>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="statusTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Change Target Status </span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog> -->


    <!-- delete  targets -->
    <v-dialog persistent v-model="deletedialog" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs6 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Verification
                </span>
              </v-flex>
              <v-flex xs6 align-self-center text-right>
                <v-btn icon color="white" text @click="deletedialog = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 align-self-center text-left>
                <span class="kumbhBold" style="color: #000; font-size: 20px">Are you sure you want to delete this
                  target?</span>
              </v-flex>

              <v-flex xs12 align-self-center text-right pt-3>
                <v-btn @click="confirmDelete" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Delete Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <!-- upload target sheet - excel -->
    <v-dialog persistent v-model="uploadTargets" max-width="600px">
      <v-card>
        <v-layout wrap>
          <v-flex xs12 pa-4 text-left align-self-center style="background: #005f32 !important">
            <v-layout wrap>
              <v-flex xs10 align-self-center text-left>
                <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
                  Target Details - Upload Excel Sheet</span>
              </v-flex>
              <v-flex xs2 align-self-center text-right>
                <v-btn icon color="white" text @click="uploadTargets = false">
                  <v-icon color="#ffffff">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 pa-4></v-flex>
          <v-flex xs12>
            <v-layout wrap px-5 pb-8>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-select v-model="financialYear1" :items="financialYears1" label="Financial Year" required outlined dense
                  hide-details></v-select>
              </v-flex>
              <v-flex xs12 sm12 align-self-center text-left pa-2>
                <v-card outlined class="pa-2">

                  <v-flex xs12 sm12 align-self-center text-left pa-2 v-if="uploadedFileName"
                    style="font-family:kumbhMedium">
                    <strong>Uploaded File :</strong> {{ uploadedFileName }}
                  </v-flex>
                  <v-flex xs12 sm12 align-self-center text-end pa-2>
                    <v-btn color="#005f32" dark small @click="$refs.targetSheet.click()">
                      Upload Excel Sheet
                      <v-icon size="18px">mdi-upload</v-icon>
                    </v-btn>
                    <input v-show="false" ref="targetSheet" type="file" accept=".xlsx, .xls"
                      @change="handleFileUpload" />

                  </v-flex>
                </v-card>
              </v-flex>

              <v-flex xs12 text-end pt-3 pa-2>
                <v-btn @click="uploadTarget()" color="#005f32">
                  <span class="kumbhBold" style="color: white"> Add Target </span>
                </v-btn></v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    addTargets: false,
    verifyTargets: false,
    targetDate1: "",
    targetDate: "",
    // isTargetSheetVerified: "",
    uploadTargets: false,
    targetWeightage: "",
    target: "",
    pickerDate: null,
    pickerDateString: '',
    menu: false,
    menu1: false,
    statusTargets: false,
    itemToDelete: null,
    deletedialog: false,
    showDialog: false,
    dialogContent: "",
    additionalDetails: "",
    proofDescription: "",
    tragetStatus: "",
    proofFile: [],
    uploadedFileName: "",
    // uploadedFileNames: [],
    status: "All", // Default to 'All'
    tragetStatusArray: [
      { _id: "0", name: "All" }, // Add 'All' option
      { _id: "1", name: "Met" },
      { _id: "2", name: "Not Met" },
      { _id: "3", name: "Delayed Met" },
    ],
    showsnackbar: false,
    ServerError: false,

    msg: null,
    pages: 0,
    pageCount: 1,
    appLoading: false,
    currentPage: 1,
    g: false,
    totalData: 0,
    financialYear: "",
    financialYear1: "",
    editingitem: "",
    editdialog: false,
    // filteredTragetStatusArray: [],
    financialYears: [],
    financialYears1: [],
    rules: {
      required: (value) => !!value || "Required.",
    },

    targetArray: [],
    data: [],
  }),

  mounted() {
  //  this.getData();
    this.generateFinancialYears();
    this.generateFinancialYears1();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    status() {
      this.getData();
    },

    financialYear() {
      this.getData();
    },
    financialYear1(newVal) {
      if (newVal) {
        const [startYear] = newVal.split("-").map(Number);
        this.pickerDate = new Date(`${startYear}-04-01`);
        this.pickerDateString = this.pickerDate.toISOString().substr(0, 10);
      }
    },
    pickerDate(newVal) {
      this.pickerDateString = newVal ? newVal.toISOString().substr(0, 10) : null;
    },
    targetDate(newVal) {
      console.log("Target Date Changed:", newVal);
      this.editingitem.TargetDate = this.formatDate(newVal);
    },
    editingitem: {
      handler(newVal) {
        console.log("Editing Item Updated:", newVal);
      },
      deep: true
    }

  },
  

  methods: {

    handleFileUpload(event) {
      const file = event.target.files ? event.target.files[0] : null;

      if (!file) {
        this.msg = "No file selected!";
        this.showsnackbar = true;
        return;
      }

      // Allowed file types for Excel
      const allowedTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
        "application/vnd.ms-excel" // .xls
      ];

      if (!allowedTypes.includes(file.type)) {
        this.msg = "Please upload only Excel files (.xls, .xlsx)";
        this.showsnackbar = true;
        return;
      }

      // Store the file and display the name
      this.targetSheet = file;
      this.uploadedFileName = file.name;
    },
    uploadTarget() {
      if (!this.financialYear1) {
        this.msg = "Please choose a financial year.";
        this.showsnackbar = true;
        return;
      }
      if (!this.targetSheet) {
        this.msg = "Please upload the target sheet.";
        this.showsnackbar = true;
        return;
      }


      // Prepare form data for upload
      const formData = new FormData();
      formData.append("targetSheet", this.targetSheet);
      formData.append("financialYear", this.financialYear1);

      axios({
        method: "POST",
        url: "/employee/targetSheet/upload/excel",
        data: formData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.uploadTargets = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.msg = "Failed to upload file!";
          this.showsnackbar = true;
        });
    },

    redirectToViewPage(item) {


      this.$router.push(
        `/Targets/viewTarget?targetId=${item._id}&financialYear=${this.financialYear}&TargetSheetId=${item.TargetSheetId}`
      );
    },

    updateTargetDate(selectedDate) {
      if (selectedDate) {
        this.editingitem.TargetDate = this.formatDate1(selectedDate); // Format date on update
      } else {
        console.error("Invalid selected date from picker:", selectedDate);
      }
    },

    openVerifyTargetDialog(item) {
      this.item = item; // Set the selected item
      this.verifyTargets = true; // Open the dialog
    },
    verifyTarget() {

      axios({
        method: "POST",
        url: "/verify/targetsheet/target",
        data: {
          financialYear: this.$route.query.financialYear,
          employeeId: localStorage.getItem("userid"),
          targetSheetId: this.$route.query.TargetSheetId,
          targetId: this.item._id

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.verifyTargets = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.msg = "Failed to upload file!";
          this.showsnackbar = true;
        });
    },
    generateFinancialYears() {
    const startYear = 2000;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    // If the current month is before April (0-based index), it's still the previous financial year
    const endYear = currentMonth < 3 ? currentYear : currentYear + 1;

    for (let year = startYear; year <= endYear; year++) {
      this.financialYears.push(`${year}-${year + 1}`);
    }

    // Set default financial year to the current financial year
    const defaultStartYear = currentMonth < 3 ? currentYear - 1 : currentYear;
    this.financialYear = `${defaultStartYear}-${defaultStartYear + 1}`;
  },
  generateFinancialYears1() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  // Determine the start financial year
  const startYear = currentMonth < 3 ? currentYear - 1 : currentYear;

  // Determine the end financial year
  const endYear = currentMonth < 3 ? currentYear : currentYear + 1;

  // Generate the financial years starting from the current financial year
  this.financialYears1 = [];
  for (let year = startYear; year <= endYear; year++) {
    this.financialYears1.push(`${year}-${year + 1}`);
  }

  // Set default financial year to the current financial year
  this.financialYear1 = `${startYear}-${startYear + 1}`;
},



  // Allow only dates from April 1 of the start year to February 29 of the end year
  allowedDates(date) {
    if (!this.financialYear1) return false;

    const [startYear, endYear] = this.financialYear1.split("-").map(Number);

    const startDate = new Date(`${startYear}-04-01`);
    const endDate = new Date(`${endYear}-02-29`);

    const selectedDate = new Date(date);

    return selectedDate >= startDate && selectedDate <= endDate;
  },

    addTarget() {
      if (!this.financialYear) {
        this.msg = "Please choose a financial year.";
        this.showsnackbar = true;
        return;
      }
      if (!this.targetDate1) {
        this.msg = "Please enter target date.";
        this.showsnackbar = true;
        return;
      }
      if (!this.targetWeightage) {
        this.msg = "Please enter target weightage.";
        this.showsnackbar = true;
        return;
      }

      if (!this.target) {
        this.msg = "Please enter target details.";
        this.showsnackbar = true;
        return;
      }
      this.appLoading = true;
      axios({
        url: "/employee/targetSheet/add",
        method: "POST",
        data: {
          //   employeeId: this.employee,
          financialYear: this.financialYear1,
          targetWeightage: this.targetWeightage,
          targetDate: this.targetDate1,
          target: this.target,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addTargets = false;

            this.targetWeightage = null;
            this.targetDate1 = null;
            this.target = null;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    openDialog(content) {
      this.dialogContent = content; // Set the content for the dialog
      this.showDialog = true; // Open the dialog
    },
    //VERSION 1
    // handleFileUpload(event) {
    //   const files = event.target.files;
    //   const allowedTypes = ["application/pdf"];

    //   // Validate file type and limit
    //   for (const file of files) {
    //     if (!allowedTypes.includes(file.type)) {
    //       this.msg = "Please upload only PDF files.";
    //       this.showsnackbar = true;
    //       return;
    //     }
    //     if (this.proofFile.length >= 3) {
    //       this.msg = "You can upload a maximum of 3 files.";
    //       this.showsnackbar = true;
    //       return;
    //     }
    //     this.proofFile.push(file);
    //     this.uploadedFileNames.push(file.name);
    //   }
    // },


    editItem(item) {
      this.editingitem = {
        ...item,
        TargetDate: item.TargetDate ? this.formatDate1(item.TargetDate) : null, // Ensure valid date or null
      };
      this.targetDate = this.editingitem.TargetDate || null; // Initialize targetDate for v-date-picker
      this.editdialog = true;
    },
    edit() {
      // if (!this.editingitem.financialYear) {
      //   this.msg = "Please choose a financial year.";
      //   this.showsnackbar = true;
      //   return;
      // }
      if (!this.editingitem.TargetDate) {
        this.msg = "Please enter target date.";
        this.showsnackbar = true;
        return;
      }
      if (!this.editingitem.weightage) {
        this.msg = "Please enter target weightage.";
        this.showsnackbar = true;
        return;
      }

      if (!this.editingitem.Targets) {
        this.msg = "Please enter target details.";
        this.showsnackbar = true;
        return;
      }

      this.appLoading = true;
      var data = {};
      data["financialYear"] = this.$route.query.financialYear;
      data["targetDate"] = this.editingitem.TargetDate;
      data["targetWeightage"] = this.editingitem.weightage;
      data["target"] = this.editingitem.Targets;
      data["targetSheetId"] = this.$route.query.TargetSheetId;
      data["targetId"] = this.editingitem._id;
      axios({
        url: "/employee/targetSheet/edit",
        method: "Post",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    // openStatusTargetDialog(item) {
    //   this.item = item; // Set the selected item
    //   const currentDate = new Date();
    //   const targetDate = new Date(item.TargetDate);

    //   // Filter options dynamically
    //   if (targetDate < currentDate) {
    //     this.filteredTragetStatusArray = this.tragetStatusArray.filter(
    //       (status) => status.name !== "Met"
    //     );
    //   } else {
    //     this.filteredTragetStatusArray = [...this.tragetStatusArray];
    //   }

    //   this.statusTargets = true; // Open the dialog
    // },
    // statusTarget() {
    //   if (!this.tragetStatus) {
    //     this.msg = "Please choose Target Status.";
    //     this.showsnackbar = true;
    //     return;
    //   }
    //   if (!this.additionalDetails) {
    //     this.msg = "Please enter additional details.";
    //     this.showsnackbar = true;
    //     return;
    //   }
    //   if (!this.proofDescription) {
    //     this.msg = "Please enter description.";
    //     this.showsnackbar = true;
    //     return;
    //   }
    //   if (!this.proofFile || this.proofFile.length === 0) {
    //     this.msg = "Please upload at least one proof file.";
    //     this.showsnackbar = true;
    //     return;
    //   }
    //   if (!this.item || !this.item._id) {
    //     this.msg = "Target ID is missing.";
    //     this.showsnackbar = true;
    //     return;
    //   }

    //   // Prepare form data for upload
    //   const formData = new FormData();
    //   formData.append("employeeId", localStorage.getItem("userid"));
    //   formData.append("targetId", this.item._id);
    //   formData.append("targetSheetId", this.$route.query.TargetSheetId);
    //   formData.append("tragetStatus", this.tragetStatus);
    //   formData.append("additionalDetails", this.additionalDetails);
    //   formData.append("proofDescription", this.proofDescription);
    //   // VERSION 1
    //   // this.proofFile.forEach((file, index) => {
    //   //   formData.append(`proofFile[${index}]`, file);
    //   // });

    //   //     VERSION2
    //   //     this.proofFile.forEach((file) => {
    //   //   formData.append('proofFile[]', file); // Same key for all files
    //   // });

    //   this.proofFile.forEach(file => {
    //     formData.append('proofFile', file); // Append each file as 'proofFile[]'
    //   });      // Create a single array for all files


    //   // const proofFileArray = [];
    //   //   this.proofFile.forEach((file) => {
    //   //     proofFileArray.push(file); // Collect files into an array
    //   //   });

    //   //   // Append the array as a single key
    //   //   formData.append("proofFile", JSON.stringify(proofFileArray));
    //   axios({
    //     method: "POST",
    //     url: "/employee/update/target/status",
    //     data: formData,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status === true) {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //         this.statusTargets = false;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       console.error(err);
    //       this.msg = "Failed to upload file!";
    //       this.showsnackbar = true;
    //     });
    // },
    getData() {
    this.appLoading = true;
    axios({
      url: "/employee/target/list",
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
        financialYear: this.financialYear, // Pass the selected or default financial year
        targetStatus: this.status,
      },
    })
      .then((response) => {
        this.appLoading = false;
        const allTargets = response.data.data.map((item) => ({
          financialYear: item.financialYear,
          metCount: item.metCount,
          notMetCount: item.notMetCount,
          delayedMetCount: item.delayedMetCount,
          totalWeightage: item.totalWeightage,
          totalScore: item.totalScore,
          targets: item.targets, // Nested targets for table display
        }));
        this.targetArray = allTargets;
        this.totalData = allTargets.length;

        // Set default financial year if not already selected
        if (!this.financialYear && response.data.data.length > 0) {
          this.financialYear = response.data.data[0].financialYear;
        }
      })
      .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.error(err);
      });
  },


    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deletedialog = true;
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deleteItem(this.itemToDelete);
      }
      this.deletedialog = false;
    },
    deleteItem(r) {
      var data = {};
      data["targetId"] = r._id;
      data["targetSheetId"] = this.$route.query.TargetSheetId;
      axios({
        url: "/employee/target/delete",
        method: "post",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    formatDate(date) {
  if (!date) return "";
  const d = new Date(date);
  if (isNaN(d)) {
    console.error("Invalid date in formatDate:", date);
    return ""; // Handle invalid dates gracefully
  }
  return d.toISOString().split("T")[0]; // Format as YYYY-MM-DD
},
   formatDate1(date) {
  if (!date) return "";
  const d = new Date(date);
  if (isNaN(d)) {
    console.error("Invalid date in formatDate1:", date);
    return ""; // Handle invalid dates gracefully
  }
  return d.toISOString().split("T")[0]; // Format as YYYY-MM-DD
}
  },
};
</script>
<style>
.tableHead {
  font-size: 15px;
  color: #ffffff;
  font-family: kumbhMedium;
}
</style>