<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >ID Proofs (Documents shall be added only in PDF format)</span
        >
      </v-flex>
      <v-flex xs12 pt-5>
       
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Adhar Card Number</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.adharCardNum"
                  label="Adhar Card Number"
                  outlined
                  dense
                
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Adhar Card File</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.adharCardFile">
           
            <v-layout wrap align-center>
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.adharCardFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Adhar Card File
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="adharCardFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ adharCardFile.name.slice(0, 40) }}
                      <span v-if="adharCardFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
            
          </v-flex>
          

          <v-flex xs12 sm6 md8 text-left v-else>
          
            <v-layout wrap>
              <span style="color: red; font-size:12px">Adhar Card File Not Uploaded</span>
            </v-layout>
         
          </v-flex>
        </v-layout>
      
      <br>   <v-divider class="py-3"></v-divider> 
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Pan Card Number</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
         
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.panCardNum"
                  label="Pan Card Number"
                  outlined
                  dense
                 
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
         
          </v-flex>
        </v-layout>
        
        <v-layout wrap >

          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Pan Card File</span>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.panCardFile">
           
            <v-layout wrap align-center>
             
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.panCardFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Pan Card File
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="panCardFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ panCardFile.name.slice(0, 40) }}
                      <span v-if="panCardFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>

            
          </v-flex>
      

          <v-flex xs12 sm6 md8 text-left v-else>
            <span style="color: red; font-size:12px">Pan Card File Not Uploaded</span>
          </v-flex>
        </v-layout>
        <br>    <v-divider class="py-3"></v-divider> 
   
        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Passport Or Licence Number </span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="mydocs.documentDetails.passportOrLicenceNum"
                  label="Passport Or Licence Number"
                  outlined
                  dense
                 
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
         <v-layout wrap >
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Passport/Licence File</span>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.passportOrLicenceFile">
            
            <v-layout wrap align-center>
              <v-flex xs12 >
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.passportOrLicenceFile">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Pan Card File
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          
            <v-flex xs12>
              <v-chip
                v-if="passportOrLicenceFile.name"
                class="ma-2"
                outlined
                color="#005f32"
                text-color="#929292"
              >
                <v-layout wrap justify-center>
                  <v-flex xs12 text-left pa-2>
                    <span>{{ passportOrLicenceFile.name.slice(0, 40) }}
                      <span v-if="passportOrLicenceFile.name.length > 40">
                        ...
                      </span></span
                    >
                  </v-flex>
                </v-layout>
              </v-chip>
            </v-flex>
          </v-flex>

          <v-flex xs12 sm6 md8 text-left v-else>
            <span style="color: red; font-size:12px">Passport Or Licence File  Not Uploaded</span>
          </v-flex>
          
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar : false,
      timeout: 5000,
      msg: null,
      adharCardFile: "",
      panCardFile: "",
      passportOrLicenceFile: "",
      adharCardNum: "",
      panCardNum: "",
      passportOrLicenceNum : "",
      mydocs:{},
      formData: new FormData(),
    };
  },
  mounted() {
    this.getMyUploads();
  },
  methods: {
    getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/employee/idproof/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    }
  },
};
</script>