<template>
    <div>
      <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
  
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>

      <v-layout wrap justify-center>
        <v-flex xs12>
          <span class="kumbhBold" style="font-size: 18px"
            >Insurance & Financial Details (Docs shall be added only in PDF form.)</span
          >
        </v-flex>
        <v-flex xs12 pt-5>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Employee Name</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.employeeDetails">
              <v-layout wrap>
                <v-flex xs12>
               
                  
                  <v-text-field  disabled
                    v-model="mydocs.employeeDetails.name"
                    label="Employee Name"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Employee ID</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.employeeDetails">
              <v-layout wrap>
                <v-flex xs12>
               
                  
                  <v-text-field  disabled
                    v-model="mydocs.employeeDetails.empId"
                    label="Employee ID"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">A/C Number</span>
              <span class="red--text">*</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
               
                  
                  <v-text-field  
                    v-model="mydocs.documentDetails.acNum"
                    label="A/C Number"
                    outlined
                    dense
                    :rules="[v => !!v || 'A/C Number is required']"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">IFSC Code</span>
              <span class="red--text">*</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
               
                  
                  <v-text-field  
                    v-model="mydocs.documentDetails.ifsc"
                    label="IFSC Code"
                    outlined
                    dense
                    :rules="[v => !!v || 'IFSC Code is required']"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          
<template v-if="mydocs && mydocs.employeeDetails && mydocs.employeeDetails.maritalStatus ==='Married'">
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Spouse Name</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
               
                  
                  <v-text-field 
                    v-model="mydocs.documentDetails.spouseName"
                    label="Spouse Name"
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

       

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Spouse's DOB</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
                  <v-menu
                    ref="menuspouse"
                    v-model="menuspouse"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="mydocs.documentDetails.spouseDOB"
                        label="Spouse's DOB"
                        
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="mydocs.documentDetails.spouseDOB"
                      @input="menuspouse = false"
                      :max="new Date().toISOString().substr(0, 10)" 
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <div>
            <div v-for="(child, index) in childrenDetails" :key="index">
              <v-layout wrap pt-5>
                <v-flex xs12 sm6 md4>
                  <span class="kumbhSemibold">Name of Child {{ index + 1 }}</span>
                </v-flex>
                <v-flex xs12 sm6 md8 text-right>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-text-field
                        v-model="child.childrenName"
                        :label="'Name of Child ' + (index + 1)"
                        outlined
                        dense
                      ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
        
              <v-layout wrap pt-5>
                <v-flex xs12 sm6 md4>
                  <span class="kumbhSemibold">Date of birth of Child {{ index + 1 }}</span>
                </v-flex>
                <v-flex xs12 sm6 md7 text-right>
                  <v-layout wrap>
                    <v-flex xs12>
                      <v-menu
                        :ref="'menuChild' + index"
                        v-model="child.menuOpen"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="child.childrenDOB"
                            :label="'Child ' + (index + 1) + ' DOB'"
                            outlined
                            dense
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="child.childrenDOB"
                          @input="child.menuOpen = false"
                          :max="new Date().toISOString().substr(0, 10)"
                        ></v-date-picker>
                      </v-menu>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex  xs12 sm6 md1 text-right  v-if="child._id && child._id !== ''">
                
                <v-btn icon    @click="openDeleteDialog(child._id, index)">
                  <v-icon small color="red" style="cursor: pointer; font-size: 18px;">mdi-delete</v-icon>
                </v-btn></v-flex>
                <v-flex  xs12 sm6 md1 text-right v-else><v-btn icon @click="removeChild(index)" v-if="childrenDetails.length > 1">
                    <v-icon small color="red" style="cursor: pointer; font-size: 18px;">mdi-close</v-icon>
                  </v-btn></v-flex>
              </v-layout>
              <v-layout wrap justify-end>
              <!-- Close button to remove child -->
             
              </v-layout>
            </div>
        
            <!-- Button to add the second child's fields -->
            <!-- <v-btn v-if="childrenDetails.length < 2" @click="addChild">
              Add Child
            </v-btn> -->
            <v-layout wrap justify-end >
            <v-btn v-if="childrenDetails.length < 2" @click="addChild" small color="#00994d" dark >
              <v-icon left>mdi-plus</v-icon>
              Add Child
            </v-btn>
            </v-layout>
          </div>

        </template>

         

          <v-layout wrap pt-4>
            <v-flex xs12 sm6 md4 pt-3>
              <span class="kumbhSemibold">Bank File</span>
              <span class="red--text">*</span>
            </v-flex>
  
            <v-flex xs12 sm6 md8 text-left v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.bankfilename">
              <v-card outlined class="pa-3">
              <v-layout wrap align-center>
                <v-flex xs12 px-2>
                  <a target="_blank" :href="mediaURL + mydocs.documentDetails.bankfilename">
                    <v-btn
                      style="border-radius: 14px; border: 0.5px solid #707070"
                      px-5
                      pt-0
                      text
                      small
                      outlined
                    >
                      View Bank File
                    </v-btn>
                  </a>
  &nbsp;
                  <v-btn
                  color="#005f32"
                  dark
                  small
                  @click="$refs.bankfilename.click()"
                  style="border-radius: 14px; font-size: 12px; letter-spacing: 0px;"
                >
                  UPLOAD NEW
                </v-btn>
                <input
                  v-show="false"
                  id="file"
                  ref="bankfilename"
                  type="file"
                  accept="application/pdf"
                  @change="bankDetailsUpload($event)"
                />
                </v-flex>
              
                 
              
              </v-layout>
            </v-card>
              <v-flex xs12>
                <v-chip
                  v-if="bankfilename.name"
                  class="ma-2"
                  outlined
                  color="#005f32"
                  text-color="#929292"
                >
                  <v-layout wrap justify-center>
                    <v-flex xs12 text-left pa-2>
                      <span>{{ bankfilename.name.slice(0, 40) }}
                        <span v-if="bankfilename.name.length > 40">
                          ...
                        </span></span
                      >
                    </v-flex>
                  </v-layout>
                </v-chip>
              </v-flex>
            </v-flex>
  
            <v-flex xs12 sm6 md8 text-left v-else>
              <v-card outlined class="pa-3">
              <v-layout wrap>
                <v-flex xs12>
                  <v-chip
                    v-if="bankfilename.name"
                    class="ma-2"
                    outlined
                    color="#005f32"
                    text-color="#929292"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 text-left pa-2>
                        <span
                          >{{ bankfilename.name.slice(0, 40) }}
                          <span v-if="bankfilename.name.length > 40">
                            ...
                          </span></span
                        ></v-flex
                      >
                    </v-layout>
                  </v-chip>
                  <v-btn color="#005f32" dark small @click="$refs.bankfilename.click()"
                    ><span
                      style="
                        font-size: 12px;
                        text-align: end;
                        letter-spacing: 0px;
                        opacity: 1;
                      "
                    >
                      UPLOAD Bank File
                    </span></v-btn
                  >
                  <input
                    v-show="false"
                    id="file"
                    ref="bankfilename"
                    type="file"
                    accept="application/pdf"
                    @change="bankDetailsUpload($event)"
                  />
                </v-flex>
              </v-layout>
              </v-card>
            </v-flex>
            
          </v-layout>
         
         
        
        </v-flex>
      </v-layout>
      <v-layout wrap justify-cenendter py-5>
        <v-flex xs12 text-end>
          <v-btn color="#005f32" dark @click="add()"
            ><span>Save Details</span></v-btn
          >
        </v-flex>
      </v-layout>
        <!-- Confirmation Dialog -->
 <v-dialog persistent v-model="deleteDialog" max-width="600px">
  <v-card>
    <v-layout wrap>
      <v-flex
        xs12
        pa-4
        text-left
        align-self-center
        style="background: #005f32 !important"
      >
        <v-layout wrap>
          <v-flex xs6 align-self-center text-left>
            <span class="kumbhBold" style="color: #ffffff; font-size: 20px">
              Delete Child</span
            >
          </v-flex>
          <v-flex xs6 align-self-center text-right>
            <v-btn
              icon
              color="white"
              text
              @click="deleteDialog = false"
            >
              <v-icon color="#ffffff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pa-4></v-flex>
      <v-flex xs12>
        <v-layout wrap px-5 pb-8>
          <v-flex xs12 align-self-center text-left>
            <span class="kumbhBold" style="color: #000; font-size: 20px"
              >Are you sure you want to delete this Chil details?</span
            >
          </v-flex>

          <v-flex xs12 align-self-center text-right pt-3>
            <v-btn     @click="deleteDialog = false" color="white">
              <span class="kumbhBold" style="color: black"> Cancel </span>
            </v-btn> &nbsp;
            <v-btn   @click="confirmDelete" color="#005f32">
              <span class="kumbhBold" style="color: white"> Delete </span>
            </v-btn></v-flex
          >
        </v-layout>
      </v-flex>
    </v-layout>
  </v-card>
</v-dialog>

    </div>
  </template>
  <script>
  import axios from "axios";
  export default {
    data() {
      return {
        showSnackBar : false,
        timeout: 5000,
        msg: null,
        appLoading: false,
        menuspouse: false,
        menuchild:false,
        ServerError: false,
        mydocs:{},
        pfAndEsic: "",
        nominationDetails: "",
        InsuranceDetails: "",
        bankfilename : "",
        formData: new FormData(),
        childrenDetails: [
        {
          childrenName: "",
          childrenDOB: null,
          menuOpen: false,
          _id: null
        },
      ],
      deleteDialog: false,
      deleteChildId: null,
      deleteChildIndex: null,
      };
    },
    mounted() {
      //this.getData();
      this.getMyUploads();
    },
    methods: {
      addChild() {
      if (this.childrenDetails.length < 2) {
        this.childrenDetails.push({
          childrenName: "",
          childrenDOB: null,
          menuOpen: false,
          _id: null
        });
      }
    },
    removeChild(index) {
      if (this.childrenDetails.length > 1) {
        this.childrenDetails.splice(index, 1);
      }
    },
      bankDetailsUpload(event) {
        this.bankfilename = event.target.files[0];
      },
   
      getMyUploads() {
  this.appLoading = true;
  axios({
    url: "/insuranceBankDetails/view",
    method: "GET",
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
    params: {
      id: localStorage.getItem("userid"),
    },
  })
    .then((response) => {
      this.appLoading = false;
      if (response.data.status) {
        this.mydocs = response.data.data;
        
        // Ensure `childrenDetails` array is populated from the response
        this.childrenDetails = this.mydocs.documentDetails.childrenDetails || [];
      } else {
        this.showSnackBar = true;
        this.msg = response.data.msg;
      }
    })
    .catch((err) => {
      this.appLoading = false;
      this.ServerError = true;
      console.log(err);
    });
},

      
add() {
  // Validate required fields
  const acNumRegex = /^[0-9]{9,18}$/; // Bank account number regex
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/; // IFSC code regex

  // Check if bank file is uploaded
 

  if (!this.mydocs.documentDetails.acNum) {
    this.showSnackBar = true;
    this.msg = "Please enter A/C Number";
    return;
  }
  if (!acNumRegex.test(this.mydocs.documentDetails.acNum)) {
    this.showSnackBar = true;
    this.msg = "Invalid A/C Number. It should be between 9-18 digits.";
    return;
  }

  if (!this.mydocs.documentDetails.ifsc) {
    this.showSnackBar = true;
    this.msg = "Please enter IFSC Code";
    return;
  }
  if (!ifscRegex.test(this.mydocs.documentDetails.ifsc)) {
    this.showSnackBar = true;
    this.msg = "Invalid IFSC Code. Format: ABCD0123456";
    return;
  }
  if (!this.bankfilename) {
    this.showSnackBar = true;
    this.msg = "Please upload the bank file";
    return;
  }

  // Proceed with form submission if validations pass
  let formData = new FormData();
  formData.append("id", localStorage.getItem("userid"));
  formData.append("empId", this.mydocs.employeeDetails.empId);
  formData.append("empname", this.mydocs.employeeDetails.name);
  formData.append("acNum", this.mydocs.documentDetails.acNum);
  formData.append("spouseName", this.mydocs.documentDetails.spouseName);
  formData.append("ifsc", this.mydocs.documentDetails.ifsc);
  formData.append("spouseDOB", this.mydocs.documentDetails.spouseDOB);
  formData.append("bankfilename", this.bankfilename);

  // Exclude "menuOpen" from the childrenDetails array
  const sanitizedChildren = this.childrenDetails.map(({ childrenName, childrenDOB, _id}) => ({
    childrenName,
    childrenDOB,
    _id
  }));

  // Append each child to formData without JSON.stringify
  sanitizedChildren.forEach((child, index) => {
    formData.append(`childrenDetails[${index}][childrenName]`, child.childrenName);
    formData.append(`childrenDetails[${index}][childrenDOB]`, child.childrenDOB);
    formData.append(`childrenDetails[${index}][_id]`, child._id);
  });

  axios({
    method: "POST",
    url: "/employee/insuranceBankDetails/upload",
    data: formData,
    headers: {
      "x-auth-token": localStorage.getItem("token"),
      "Content-Type": "multipart/form-data",
    },
  })
    .then((response) => {
      if (response.data.status == true) {
        this.msg = response.data.msg;
        this.showSnackBar = true;
        formData = new FormData();
        this.pfAndEsic = "";
        this.bankfilename = "";
        this.getMyUploads();
        this.appLoading = false;
      } else {
        this.appLoading = false;
        this.msg = response.data.msg;
        this.showSnackBar = true;
        formData = new FormData();
      }
    })
    .catch((err) => {
      this.ServerError = true;
      console.log(err);
    });
},
    openDeleteDialog(degreeId, index) {
      this.deleteChildId = degreeId;
      this.deleteChildIndex = index;
      this.deleteDialog = true;
    },

    // Confirm deletion and call deleteDegree()
    confirmDelete() {
      this.deleteDialog = false;
      this.deleteChild(this.deleteChildId, this.deleteChildIndex);
    },

    deleteChild(child, index) {
      const data = { childId: child };

      axios({
        url: "/employee/insurenceChild/delete",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Successfully";
            this.showsnackbar = true;
            this.getMyUploads();
            // Remove the degree locally after successful deletion from the server
            this.degrees.splice(index, 1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    },
  };
  </script>