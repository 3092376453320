<template>
    <div>
        <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
      
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
      <v-layout wrap justify-center>
        
        <v-flex xs12>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Alternate Number</span>
              <span class="red--text">*</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="mydocs.documentDetails.alternateNumber"
                    label="Alternate Number"
                    outlined
                    dense
                    readonly
                    
                    :rules="[rules.required, rules.numberOnly]"
                    maxlength="10"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Emergency Contact Details</span>
              <span class="red--text">*</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
                   <v-text-field
                    v-model="mydocs.documentDetails.emergencyContactDetails"
                    label="Emergency Contact Details"
                    outlined
                    dense
                    readonly
                    
                    :rules="[rules.required, rules.numberOnly]"
                    maxlength="10"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>

          <v-layout wrap pt-5>
            <v-flex xs12 sm6 md4>
              <span class="kumbhSemibold">Permanent Address</span>
              <span class="red--text">*</span>
            </v-flex>
            <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails">
              <v-layout wrap>
                <v-flex xs12>
                   <v-textarea
                    v-model="mydocs.documentDetails.permanentAddress"
                    label="Permanent Address"
                    outlined
                    dense
                    readonly
                    
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        showSnackBar : false,
        timeout: 5000,
        msg: null,
        ServerError: false,
        mydocs:{},
        alternateNumber: "",
        emergencyContactDetails: "",
        permanentAddress: "",
        formData: new FormData(),
        rules: {
          required: value => !!value || 'This field is required',
          numberOnly: value => {
            const pattern = /^[0-9]{10}$/
            return pattern.test(value) || 'Please enter a valid 10-digit number'
          }
        }
      };
    },
    mounted() {
    this.getMyUploads();
  },
    methods: {
      getMyUploads() {
      this.appLoading = true;
      axios({
        url: "/employeeContactDetails/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.mydocs = response.data.data;
          } else {
            this.showsnackbar = false;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err());
        });
    }
    },
  };
  </script>