<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#005f32" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span class="kumbhBold" style="font-size: 18px"
          >Past Experience (Documents shall be added only in PDF format)</span
        >
      </v-flex>

      <v-flex xs12 pt-5>
        <v-layout wrap>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Experience certificate from last organization</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.experienceCertificateLastOrg">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.experienceCertificateLastOrg">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Experience Certificate (Past Organization)
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md7 text-right v-else>
            <span style="color: red; font-size:12px">Experience certificate from last organization is Not Uploaded</span>
          </v-flex>
        </v-layout>

        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">Salary Slip</span>
          </v-flex>
           <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.salarySlip">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.salarySlip">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                    View Salary Slip
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md7 text-right v-else>
            <span style="color: red; font-size:12px">Salary Slip  Not Uploaded</span>
          </v-flex>
        </v-layout>

        <v-layout wrap pt-5>
          <v-flex xs12 sm6 md4>
            <span class="kumbhSemibold">CV</span>
          </v-flex>
          <v-flex xs12 sm6 md8 text-right v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.cv">
            <v-layout wrap>
              <v-flex xs12>
                <a target="_blank" :href="mediaURL + mydocs.documentDetails.cv">
                  <v-btn
                    style="border-radius: 14px; border: 0.5px solid #707070"
                    px-5
                    pt-0
                    text
                    small
                    outlined
                  >
                   View CV
                  </v-btn>
                </a>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md7 text-right v-else>
            <span style="color: red; font-size:12px">CV  Not Uploaded</span>
          </v-flex>
        </v-layout>
     
        <v-divider class="my-6"></v-divider>
        
        <v-layout wrap>
          <v-flex xs12>
            <span class="kumbhBold" style="font-size: 16px">Experience Details</span>
          </v-flex>
        </v-layout>
        <v-flex pt-3> 

        <v-card outlined class="pa-4">
        <v-layout wrap pt-4 v-for="(expe, index) in experiences" :key="index">
          <v-flex xs12>
              <v-layout wrap align-center>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="expe.details"
                    label="Experience Details"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </v-flex>
             
                <v-flex xs12 sm6 text-right v-if="expe.experienceFile.length>0">
                  <v-layout wrap>
                    <v-flex xs12>
                      <a target="_blank" :href="mediaURL + expe.experienceFile">
                        <v-btn
                          style="border-radius: 14px; border: 0.5px solid #707070"
                          px-5
                          pt-0
                          text
                          small
                          outlined
                        >
                          View Experience Certificate  
                        </v-btn>
                      </a>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm5  text-right v-else>
                  <span style="color: red; font-size:12px">Experience Certificate  Not Uploaded</span>
                </v-flex>
              </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      salarySlip: "",
      cv:"",
      experienceCertificateLastOrg: "",
      experiences: [
        {details: "", experienceFile: ""}
      ],
      formData: new FormData(),
      mydocs:{},
    };
  },
  mounted() {
    this.getMyUploads();
  },
  methods: {
    getMyUploads() {
    this.appLoading = true;
    axios({
      url: "/experienceDetails/view",
      method: "GET",
      headers: {
        "x-auth-token": localStorage.getItem("token"),
      },
      params: {
       id: this.$route.query.id,
      },
    })
      .then((response) => {
        this.appLoading = false;
        if (response.data.status) {
          this.mydocs = response.data.data;

          if (this.mydocs.documentDetails.pastExperienceDetails) {
            this.experiences = this.mydocs.documentDetails.pastExperienceDetails.map(expe => ({
              _id: expe._id,
              details: expe.details,
              experienceFile: expe.experienceFile
            }));
          }

        } else {
          this.showSnackBar = true;
          this.msg = response.data.msg;
        }
      })
      .catch((err) => {
        this.appLoading = false;
        this.ServerError = true;
        console.log(err);
      });
  },
  },
};
</script>