<template>
    <div>
        <v-layout wrap justify-center>
            <v-flex xs12>
                <v-form>
                    <v-layout wrap>


                        <v-flex xs12 align-self-center px-3>
                            <v-layout wrap>
                                <v-flex xs12  align-self-center text-left pa-2 px-4>
                                    <span class="itemHeading">Personal Details</span>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.employeeDetails" >
                                    <v-text-field readonly v-model="mydocs.employeeDetails.empname" label="Name of the member" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field readonly v-model="mydocs.documentDetails.fathersName" label="Father's Name / Spouse's Name" required outlined dense></v-text-field>
                                </v-flex>

                                <!-- <v-flex xs12 sm6 align-self-center text-left pa-2 px-4>
                                    <v-text-field label="" required outlined dense></v-text-field>
                                </v-flex> -->
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                    <v-menu
                                    ref="menu"
                                    v-model="menu" 
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y readonly
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                      v-model="mydocs.employeeDetails.dob"
                                        label="DOB" readonly
                                        
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="dob"
                                      @input="menu = false" readonly disabled
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field readonly label="Email"   v-model="mydocs.documentDetails.emailId" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                    <v-text-field  readonlylabel="Phone Number" readonly  v-model="mydocs.employeeDetails.mobileNo"  required outlined type="number"
                                        dense></v-text-field>
                                </v-flex>
                                <v-flex v-if="mydocs && mydocs.employeeDetails"
                                xs12
                                sm6
                                align-self-center
                                text-left
                                pa-2
                                px-4
                              >
                              <v-select readonly
                               v-model="mydocs.employeeDetails.gender"
                              :items="['Male', 'Female', 'Transgender']"
                              label="Gender"
                              outlined
                              dense
                            ></v-select>
                              </v-flex>

                             

                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-select
                                v-model="mydocs.documentDetails.maritalStatus" readonly
                                    :items="['Married', 'Unmarried','Widow','Widower','Divorcee']"
                                    label="Marital Status"
                                    outlined
                                    dense
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12  align-self-center text-left pa-2 px-4>
                                    <span class="itemHeading">Present Employment Details</span>
                                </v-flex>

                                <v-flex xs12  align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.employeeDetails">
                                    <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field readonly
                                        v-model="mydocs.employeeDetails.dateofjoining"
                                        label="Date of joining"
                                        
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="mydocs.employeeDetails.dateofjoining" readonly disabled
                                      @input="menu2 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>

                                <v-flex xs12  align-self-center text-left pa-2 px-4>
                                    <span class="itemHeading">KYC Details</span>
                                </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="Bank Account Number" readonly v-model="mydocs.documentDetails.bankAcNum"  outlined dense></v-text-field>
                                </v-flex>


                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="IFS Code of the branch" readonly  v-model="mydocs.documentDetails.IfcCode"  required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="AADHAR Number"  readonly v-model="mydocs.documentDetails.adharNum" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="Permanent Account Number (PAN), if available " readonly v-model="mydocs.documentDetails.panNum"  required outlined dense></v-text-field>
                                </v-flex>
                               
                                <v-flex xs12 align-self-center text-left pa-2 px-7 v-if="mydocs && mydocs.documentDetails">
                                  <v-layout row align-center>
                                    <span class="itemText2 mr-1">
                                      Whether earlier a member of Employees' Provident Fund Scheme, 
                                      1952 
                                    </span>
                                    <v-checkbox v-model="mydocs.documentDetails.EPFS1952"
                                      color="primary" readonly
                                      hide-details
                                      class="mt-0"
                                    ></v-checkbox>
                                  </v-layout>
                              </v-flex>

                              <v-flex xs12 align-self-center text-left pt-10 pa-2 px-7 v-if="mydocs && mydocs.documentDetails">
                                  <v-layout row align-center>
                                    <span class="itemText2 mr-16">
                                      Whether earlier a member of Employees' Pension Scheme, 1995
                                    </span>
                                    <v-checkbox v-model="mydocs.documentDetails.EPS1995"
                                      color="primary"
                                      hide-details readonly
                                      class="mt-0"
                                    ></v-checkbox>
                                  </v-layout>
                              </v-flex>
                              <template v-if="mydocs && mydocs.documentDetails && (mydocs.documentDetails.EPFS1952 || mydocs.documentDetails.EPS1995)">


                                  <v-flex xs12  align-self-center text-left pa-2 px-4 pt-8 >
                                    <span class="itemHeading">Previous Employment Details - Un-exempted </span>
                                </v-flex>

                                  <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="Establishment Name & Address" v-model="mydocs.documentDetails.CompName" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label=" Universal Account Number"  v-model="mydocs.documentDetails.UniversalAcNum" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="PF Account Number "  v-model="mydocs.documentDetails.PFAcNum" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-menu
                                    ref="menu3"
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field v-model="mydocs.documentDetails.unexpdateofjoining"
                                      
                                        label="Date of joining (DD/MM/YYYY)"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="mydocs.documentDetails.unexpdateofjoining"
                                      @input="menu3 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-menu
                                    ref="menu4"
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                     v-model="mydocs.documentDetails.dateofExist"
                                        label="Date of exit (DD/MM/YYYY)"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="dob"
                                      @input="menu4 = false"
                                      :max="new Date().toISOString().substr(0, 10)" 
                                    ></v-date-picker>
                                  </v-menu>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="Scheme Certificate No. (if issued) " v-model="mydocs.documentDetails.SchemeCertifNum" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="PPONumber (if issued)  " v-model="mydocs.documentDetails.PPONum" required outlined dense></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                    <v-text-field label="Non Contributory Period (NCP) Days " v-model="mydocs.documentDetails.NonContributoryPeriod"  required outlined dense></v-text-field>
                                </v-flex>



                                
                                <v-flex xs12  align-self-center text-left pa-2 px-4 pt-8>
                                  <span class="itemHeading">Previous Employment Details - For Exempted Trusts </span>
                              </v-flex>

                                <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-text-field label="Name & Address of the Trust"  v-model="mydocs.documentDetails.trustName" required outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-text-field label=" Universal Account Number (UAN)" v-model="mydocs.documentDetails.UAN"   required outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-text-field label="Member EPS A/C Number" required  v-model="mydocs.documentDetails.memberEPSnum" outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-menu
                                  ref="menu5"
                                  v-model="menu5"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                   v-model="mydocs.documentDetails.epsdateofjoining"
                                      label="Date of joining (DD/MM/YYYY)"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="mydocs.documentDetails.epsdateofjoining"
                                    @input="menu5 = false"
                                    :max="new Date().toISOString().substr(0, 10)" 
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-menu
                                  ref="menu6"
                                  v-model="menu6"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    v-model="mydocs.documentDetails.epsdateofExist"
                                      label="Date of exit (DD/MM/YYYY)"
                                      readonly
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="mydocs.documentDetails.epsdateofExist"
                                    @input="menu6 = false"
                                    :max="new Date().toISOString().substr(0, 10)" 
                                  ></v-date-picker>
                                </v-menu>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-text-field label="Scheme Certificate No. (if issued) " v-model="mydocs.documentDetails.SchemeCertifNum"  required outlined dense></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                  <v-text-field label="Non Contributory Period (NCP) Days " v-model="mydocs.documentDetails.epsNonContributoryPeriod" required outlined dense></v-text-field>
                              </v-flex>
                            </template>


                              <v-flex xs12   align-self-center text-left pt-8 pa-2 px-7 v-if="mydocs && mydocs.documentDetails">
                                <v-layout row align-center>
                                  <span class="itemHeading mr-16">
                                    International Worker
                                  </span>
                                  <v-checkbox  v-model="mydocs.documentDetails.inertnationalWorker"
                                    color="primary"  
                                    hide-details readonly
                                    class="mt-0"
                                  ></v-checkbox>
                                </v-layout>
                              </v-flex>
                              <template v-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.inertnationalWorker">

                              <v-flex xs12  pt-4 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <v-text-field label="State country of origin (India/Name of other country) " readonly v-model="mydocs.documentDetails.countryOfOrigin" required outlined dense></v-text-field>
                            </v-flex>
                            <v-flex xs12 pt-4 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                                <v-text-field label="Passport No" readonly   v-model="mydocs.documentDetails.passportNum" required outlined dense></v-text-field>
                            </v-flex>

                            <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                              <v-menu
                              ref="menu7"
                              v-model="menu7"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                v-model="mydocs.documentDetails.validityOfPassportStart"
                                  label="Validity of passport- Start Date"
                                  readonly 
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="mydocs.documentDetails.validityOfPassportStart"
                                @input="menu7 = false" readonly disabled
                                :max="new Date().toISOString().substr(0, 10)" 
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 sm6 align-self-center text-left pa-2 px-4 v-if="mydocs && mydocs.documentDetails">
                            <v-menu
                            ref="menu8"
                            v-model="menu8"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                             v-model="mydocs.documentDetails.validityOfPassportEnd"
                                label="Validity of passport- End Date"
                                readonly
                                outlined
                                dense
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="mydocs.documentDetails.validityOfPassportEnd"
                              @input="menu8 = false" readonly disabled
                              :max="new Date().toISOString().substr(0, 10)" 
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>
</template>
                        <v-flex xs12 sm12 align-self-center text-left pa-2 px-4>
                          <span class="kumbhSemibold">Signature Of the employee</span>
                    </v-flex>

                    <v-flex xs12 sm12 align-self-center text-left pa-2 px-4>
                        <v-card outlined class="pa-3">
                            <v-layout wrap row align-center justify-end pa-4>
                             
                                <v-img
                                    v-if="croppedImage"
                                    :src="croppedImage"
                                    max-width="100"
                                    max-height="100"
                                    class="mb-3"
                                ></v-img>

                                <v-img
                                    v-else-if="mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfMember"
                                    :src="mediaURL + mydocs.documentDetails.signOfMember"
                                    max-width="100"
                                    max-height="100"
                                    class="mb-3"
                                ></v-img>
                                <v-flex xs12 sm6 md8 text-right pl-3 v-else>
                                  <span style="color: red; font-size:12px">Signature Is Not Uploaded</span>
                                </v-flex>
<!-- 
                                <v-btn
                                    color="#005f32"
                                    dark
                                    small
                                    @click="$refs.signOfMember.click()"
                                    class="ml-3"
                                >
                                    <span style="font-size: 12px;">{{ mydocs && mydocs.documentDetails && mydocs.documentDetails.signOfMember ? 'UPLOAD NEW Signature' : 'UPLOAD Signature' }}</span>
                                </v-btn> -->

                                <input
                                    v-show="false"
                                    ref="signOfMember"
                                    type="file"
                                    accept="image/*"
                                    @change="onImageSelected"
                                />

                                <v-dialog v-model="dialog" max-width="500">
                                    <v-card>
                                        <v-card-title class="headline">Crop your image</v-card-title>
                                        <v-card-text>
                                            <Cropper
                                                ref="cropper"
                                                :src="imagePreview"
                                                :aspect-ratio="1"
                                                :view-mode="1"
                                                style="width: 100%; height: 300px;"
                                            />
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="cropImage">Crop</v-btn>
                                            <v-btn color="secondary" text @click="closeDialog">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-layout>
                        </v-card>
                    </v-flex>

                                <!-- <v-flex xs12 sm6 pa-2 px-4 v-if="flagg">
                                  <ImageComp
                                    @stepper="winStepper"
                                    :height="'320'"
                                    :width="'320'"
                                    :heading="'Upload Profile Picture'"
                                    :componentType="'coverImage'"
                                  />
                                </v-flex> -->
                            </v-layout>
                        </v-flex>
                    </v-layout>

                    <!-- <v-layout wrap justify-center py-5>
                      <v-flex xs12 text-center>
                        <v-btn color="#005f32" dark @click="add()"
                          ><span>Save Details</span></v-btn
                        >
                      </v-flex>
                    </v-layout> -->
                </v-form>
            </v-flex>

        </v-layout>
    </div>
</template>


<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import axios from "axios";

export default {
  components: {
        Cropper,
    },
    data() {
        return {
            imagePreview: null,
            croppedImage: null,
            dialog: false,
            showSnackBar: false,
            timeout: 5000,
            msg: null,
            appLoading: false,
            mydocs: {},
            ServerError: false,
            menu: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            menu7: false,
            menu8: false,

            empname:"",
fathersName:"",
dob:"",
gender:"",
mobileNo:"",
dateofjoining:"",
emailId:"",
maritalStatus:"",
bankAcNum:"",
IfcCode:"",
adharNum:"",
dateofExist:"",
countryOfOrigin:"",
validityOfPassportEnd:"",
validityOfPassportStart:"",
panNum:"",
EPS1995:"",
CompName :"",
PFAcNum:"",
unexpdateofjoining:"",
EPFS1952:"",
UniversalAcNum:"",

            // emergencyContactNo: "",
            // permanentAddress: "",
            // temporaryAddress: "",
         
            formData: new FormData(),
        };
    },
    mounted() {
        this.getMyUploads();
    },
    methods: {
        
     
        getMyUploads() {
            this.appLoading = true;
            axios({
                url: "/employee/EPFOFormView",
                method: "GET",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
                params: {
                  id: this.$route.query.id,
                },
            })
            .then((response) => {
                this.appLoading = false;
                if (response.data.status) {
                  
                    this.mydocs = response.data.data;
                    // Assign backend data to nomineeDetails including _id
                    
                } else {
                    this.showsnackbar = true;
                    this.msg = response.data.msg;
                }
            })
            .catch((err) => {
                this.appLoading = false;
                this.ServerError = true;
                console.error(err);
            });
        },

        onImageSelected(event) {
            const file = event.target.files[0];
            if (file) {
                this.signOfMember = file;
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreview = e.target.result;
                    this.dialog = true;
                };
                reader.readAsDataURL(file);
            }
        },
        cropImage() {
            const result = this.$refs.cropper.getResult();
            if (result) {
                result.canvas.toBlob((blob) => {
                    this.croppedImage = URL.createObjectURL(blob);
                    this.signOfMember = blob;
                    this.dialog = false;
                });
            }
        },
        closeDialog() {
            this.dialog = false;
        },
        add() {
            let formData = new FormData();
            formData.append("id", localStorage.getItem("userid"));
            formData.append("signOfMember", this.signOfMember);
            
            // Employee Details
            formData.append("empname", this.mydocs.employeeDetails.empname);
            formData.append("fathersName", this.mydocs.documentDetails.fathersName);
            formData.append("dob", this.mydocs.employeeDetails.dob);
            formData.append("gender", this.mydocs.employeeDetails.gender);
            formData.append("mobileNo", this.mydocs.employeeDetails.mobileNo);
            formData.append("dateofjoining", this.mydocs.employeeDetails.dateofjoining);

            // Document Details
            formData.append("emailId", this.mydocs.documentDetails.emailId);
            formData.append("maritalStatus", this.mydocs.documentDetails.maritalStatus);
            formData.append("bankAcNum", this.mydocs.documentDetails.bankAcNum);
            formData.append("IfcCode", this.mydocs.documentDetails.IfcCode);
            formData.append("adharNum", this.mydocs.documentDetails.adharNum);
            formData.append("panNum", this.mydocs.documentDetails.panNum);
            formData.append("EPFS1952", this.mydocs.documentDetails.EPFS1952);
            formData.append("EPS1995", this.mydocs.documentDetails.EPS1995);
            
            // Previous Employment - Unexempted
            formData.append("CompName", this.mydocs.documentDetails.CompName);
            formData.append("UniversalAcNum", this.mydocs.documentDetails.UniversalAcNum);
            formData.append("PFAcNum", this.mydocs.documentDetails.PFAcNum);
            formData.append("unexpdateofjoining", this.mydocs.documentDetails.unexpdateofjoining);
            formData.append("dateofExist", this.mydocs.documentDetails.dateofExist);
            formData.append("countryOfOrigin", this.mydocs.documentDetails.countryOfOrigin);
            formData.append("validityOfPassportEnd", this.mydocs.documentDetails.validityOfPassportEnd);
            formData.append("validityOfPassportStart", this.mydocs.documentDetails.validityOfPassportStart);

            // Convert nomineeDetails array to JSON string before appending
            // Each nominee will have its _id if it exists
           

            axios({
                method: "POST",
                url: "/employee/epfo/upload",
                data: formData,
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                    "Content-Type": "multipart/form-data"
                },
            })
            .then((response) => {
                if (response.data.status === true) {
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                    formData = new FormData();
                    this.appLoading = false;
                    this.getMyUploads();
                } else {
                    this.appLoading = false;
                    this.msg = response.data.msg;
                    this.showSnackBar = true;
                    formData = new FormData();
                }
            })
            .catch((err) => {
                this.ServerError = true;
                console.error(err);
            });
        },
    },
};
</script>